html,
body,
#root {
  width: 100%;
  touch-action: manipulation;
  padding: 0;
  margin: 0;
}


@font-face {
  font-family: "Nanum Myeongjo";
  src: url("./font/NanumMyeongjo-Regular.ttf");
}

@font-face {
  font-family: "Nanum Myeongjo-Bold";
  src: url("./font/NanumMyeongjo-Bold.ttf");
}

p,
title,
h1,
h2,
h3,
h4,
h5,
h6,
a,
em,
strong,
ul,
li,
ol,
dl,
dt,
dd,
video,
audio,
table,
th,
td,
form,
button,
textarea,
select,
option,
span,
header,
footer,
nav,
article,
div {
  font-family: Nanum Myeongjo, Nanum Myeongjo-Bold !important;
}